import {
  TideItemForDestinations,
  TideItemForHotelConnection,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForRoundtripConnection,
  TideItemForSearchPage,
  TideItemForTheme,
  TideItemForThemes,
  TideItemForUspGroup,
  TideItemForWebsite,
  TideProduct,
} from "../types";
import { clamp, compact, first, isEmpty } from "lodash";
import {
  generatePath,
  generateProductPath,
  getChildNavigationLinks,
  setImageParameters,
} from "../utils";

import Breadcrumb from "../components/breadcrumb";
import Copy from "../components/copy";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Intro from "../components/intro";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import ProductCard from "../components/product-card";
import ProductCardGrid from "../components/product-card-grid";
import React from "react";
import Section from "../components/section";
import { graphql } from "gatsby";
import StickyBar from "../components/sticky-bar";
import Link from "../components/link";
import Icon from "../components/icon";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface ThemePageTemplateProps {
  data: ThemePageData;
  pageContext: {
    alternateRoutes: AlternateRoute[];
  };
}

const ThemePageTemplate: React.FC<ThemePageTemplateProps> = ({ data, pageContext }) => {
  const themesPage = data.theme.parentItem as TideItemForThemes;
  const themesPagePath = generatePath(themesPage);
  const notificationBar = first(data.notifications?.nodes);
  const { t } = useI18next();

  function getProductDuration(product: TideProduct | null) {
    let minDurationType = undefined;
    if (product?.minDurationType === 0) {
      minDurationType = t("HOURS");
    } else if (product?.minDurationType === 1) {
      minDurationType = t("DAYS");
    } else if (product?.minDurationType === 2) {
      minDurationType = t("NIGHTS");
    }
    return product?.minDuration ? `${product.minDuration} ${minDurationType}` : undefined;
  }

  return (
    <Layout>
      <Seo
        title={data.theme.content?.general?.title ?? ""}
        seoTitle={data.theme?.content?.seo?.seoTitle ?? undefined}
        seoDescription={data.theme?.content?.seo?.seoDescription ?? undefined}
        seoKeywords={data.theme?.content?.seo?.seoKeywords ?? undefined}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext.alternateRoutes}
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      <StickyBar
        navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
        actionsComponent={
          data.searchPage && (
            <Link
              href={`/${data.searchPage.content?.general?.slug}`}
              internal
              title={t("SEARCH_AND_BOOK")}
              className="cta cta--icon"
            >
              <Icon name="ui-search" />
              <Trans>SEARCH_AND_BOOK</Trans>
            </Link>
          )
        }
      />
      <Hero
        type="product"
        title={data.theme?.content?.general?.title ?? undefined}
        imageSrc={data.theme?.content?.general?.headerImage?.url ?? undefined}
      />
      <Breadcrumb
        items={compact([
          { id: "home", title: "Home", url: "/" },
          {
            id: "themes",
            title: themesPage.content?.general?.title ?? themesPage.name,
            url: themesPagePath,
          },
          {
            id: "theme",
            title: data.theme.content?.general?.title ?? data.theme.name,
            url: generatePath(data.theme, themesPagePath),
          },
        ])}
      />
      <Intro title={data.theme.content?.general?.title ?? data.theme.name}>
        <div dangerouslySetInnerHTML={{ __html: data.theme.content?.general?.intro ?? "" }}></div>
      </Intro>
      {data.hotelsForTheme.nodes.length > 0 && (
        <Section>
          <Copy centered title={t("FLIGHT_JOURNEYS_FOR_THIS_THEME")} />
          <ProductCardGrid
            columns={clamp(data.hotelsForTheme.nodes.length ?? 0, 2, 4)}
            itemsComponent={
              <>
                {data.hotelsForTheme.nodes.map((hotel) => {
                  const product = hotel?.content?.general?.product as TideProduct | null;

                  return (
                    <ProductCard
                      key={hotel.id}
                      locationNames={compact([product?.country?.name])}
                      title={hotel?.content?.general?.title ?? ""}
                      price={{
                        defaultPrice: hotel?.content?.general?.estimatedPrice ?? undefined,
                        promoPrice: hotel?.content?.general?.estimatedPromoPrice ?? undefined,
                      }}
                      imageSrc={
                        hotel?.content?.general?.headerImage?.url
                          ? setImageParameters(hotel.content.general.headerImage.url, {
                              width: 785,
                              height: 550,
                              mode: "crop",
                            })
                          : ""
                      }
                      linkUrl={generateProductPath(hotel)}
                      linkText={t("VIEW_JOURNEY")}
                      showAllIcons={true}
                      useOriginalImage={true}
                    />
                  );
                })}
              </>
            }
          />
        </Section>
      )}
      {data.roundtripsForTheme.nodes.length > 0 && (
        <Section>
          <Copy centered title={t("ROUNDTRIPS_FOR_THIS_THEME")} />
          <ProductCardGrid
            columns={clamp(data.roundtripsForTheme.nodes.length ?? 0, 2, 4)}
            itemsComponent={
              <>
                {data.roundtripsForTheme.nodes.map((roundtrip) => {
                  const product = roundtrip?.content?.general?.product as TideProduct | null;

                  return (
                    <ProductCard
                      key={roundtrip.id}
                      locationNames={compact([product?.country?.name])}
                      title={roundtrip?.content?.general?.title ?? ""}
                      description={getProductDuration(product)}
                      price={{
                        defaultPrice: roundtrip?.content?.general?.estimatedPrice ?? undefined,
                        promoPrice: roundtrip?.content?.general?.estimatedPromoPrice ?? undefined,
                      }}
                      imageSrc={
                        roundtrip?.content?.general?.headerImage?.url
                          ? setImageParameters(roundtrip.content.general.headerImage.url, {
                              width: 785,
                              height: 550,
                              mode: "crop",
                            })
                          : ""
                      }
                      linkUrl={generateProductPath(roundtrip)}
                      linkText={t("VIEW_JOURNEY")}
                      showAllIcons={true}
                      useOriginalImage={true}
                    />
                  );
                })}
              </>
            }
          />
        </Section>
      )}
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
    </Layout>
  );
};

interface ThemePageData extends LookupQueryData {
  searchPage: TideItemForSearchPage;
  theme: TideItemForTheme;
  hotelsForTheme: TideItemForHotelConnection;
  roundtripsForTheme: TideItemForRoundtripConnection;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query ThemePageQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchPage: tideItemForSearchPage(language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    theme: tideItemForTheme(id: { eq: $id }) {
      name
      content {
        general {
          title
          intro
          headerImage {
            url
          }
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      parentItem {
        ... on TideItemForThemes {
          name
          content {
            general {
              title
              slug
            }
          }
        }
      }
    }
    hotelsForTheme: allTideItemForHotel(
      filter: { content: { general: { themes: { elemMatch: { id: { eq: $id } } } } } }
    ) {
      nodes {
        id
        name
        ... on TideItemForHotel {
          content {
            general {
              title
              headerImage {
                url
              }
              estimatedPrice
              estimatedPromoPrice
              product {
                country {
                  name
                }
                minDuration
                minDurationType
              }
            }
          }
          ...productPathFragment
        }
      }
    }
    roundtripsForTheme: allTideItemForRoundtrip(
      filter: { content: { general: { themes: { elemMatch: { id: { eq: $id } } } } } }
    ) {
      nodes {
        id
        name
        ... on TideItemForRoundtrip {
          content {
            general {
              title
              headerImage {
                url
              }
              estimatedPrice
              estimatedPromoPrice
              product {
                country {
                  name
                }
                minDuration
                minDurationType
              }
            }
          }
          ...productPathFragment
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }
`;

export default ThemePageTemplate;
